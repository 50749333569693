.inline-action-btn {
  width: 32px;
  height: 32px;
}

.corner-icon-button {
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  min-width: 40px !important;
  min-height: 40px !important;
  width: 40px !important;
  height: 40px !important;
  margin-right: 10px !important;
  border-radius: 5px !important;
  background: white !important;
  padding: 0 !important;
}
