.card {
  mat-card {
    @apply px-5 pt-5 pb-1 shadow-none rounded-lg;
    mat-card-title {
      @apply text-base font-semibold mb-5;
    }

    mat-card-content {
      @apply p-0;

      .actions {
        @apply flex justify-end gap-x-4;
      }
    }
  }
}
