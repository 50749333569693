:root {
  --font-weight-regular: 400;
}

.vchry-text-body1 {
  font-family: Nunito, sans-serif !important;
  font-size: 16px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.5 !important;
  letter-spacing: normal !important;
}

.vchry-text-body2 {
  font-family: Nunito, sans-serif;
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
}

.vchry-text-body3 {
  font-family: Nunito, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
}

.vchry-text-body4 {
  font-family: Nunito, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: normal;
}

.vchry-bold {
  font-weight: 700;
}

.vchry-semi-bold {
  font-weight: 600;
}
