:root {
  --spacing-one-px: 1px;
  --spacing-xxs: 4px;
  --spacing-xs: 8px;
  --spacing-s: 12px;
  --spacing-sx: 14px;
  --spacing-m: 16px;
  --spacing-mx: 18px;
  --spacing-l: 20px;
  --spacing-lx: 22px;
  --spacing-xl: 24px;
  --spacing-xl-1: 28px;
  --spacing-xxl-2: 32px;
  --spacing-xxl-3: 36px;
  --spacing-xxl-4: 40px;
  --spacing-xxl-5: 44px;
  --spacing-xxl-6: 48px;
  --spacing-xxl-7: 52px;
  --spacing-xxl-8: 56px;
  --spacing-xxl-9: 60px;
  --spacing-xxl-10: 64px;
  --spacing-xxl-12: 72px;
  --spacing-xxl-14: 80px;
  --spacing-xxl-19: 100px;
  --spacing-xxl-24: 120px;
  --spacing-xxl-29: 120px;
  --spacing-xxl-34: 140px;
  --spacing-xxl-39: 160px;
}
