html, body {
  margin: 0;
  padding: 0;
  height: 100%;
}

body {
  font-family: Nunito, "Arial", sans-serif;
  height: min-content;
  min-height: 100%
}

body.lock-scroll {
  overflow: hidden !important;
}

h1, h2, h3, h4, h5, h6, p {
  margin: 0
}

body.light-mode .mat-app-background,
body.light-mode.mat-app-background,
body.dark-mode .mat-app-background,
body.dark-mode.mat-app-background {
  @apply bg-base-50;
}


body.dark-mode input {
  color: var(--c-text-secondary);
  caret: var(--c-text-secondary);
}

figure {
  margin: 0
}

a {
  text-decoration: none
}

*, *::after, *::before {
  box-sizing: border-box;
}
