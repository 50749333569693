i.action-ic {
  font-size: 16px;
  position: relative;
  bottom: 5px;
}

button.primary-btn {
  @apply bg-primary text-white rounded-md #{!important};

  &:disabled {
    @apply bg-base-500 text-base-200 #{!important};
  }
}
