.mat-mdc-form-field-infix {
  @apply min-h-12 py-2 px-0 #{!important};
}

.mat-mdc-select, .mat-mdc-input-element {
  @apply relative top-1;
}

.mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  @apply top-6 #{!important};
}

.v-table {
  .mat-mdc-select, .mat-mdc-input-element {
    @apply relative top-2;
  }

  th, td {
    min-width: 50px;
    max-width: 250px;
    word-wrap: anywhere;
  }
}

.mdc-notched-outline > * {
  border-color: #00000021 !important;
}

.mdc-notched-outline__notch {
  border-left: none !important;
}

.mdc-text-field--invalid .mdc-notched-outline > * {
  border-color: #B00020 !important;
  --mdc-outlined-text-field-error-label-text-color: #B00020;
  --mdc-outlined-text-field-error-focus-label-text-color: #B00020;
  --mdc-outlined-text-field-error-hover-label-text-color: #B00020;
}

.mat-form-field-disabled {
  --mdc-outlined-text-field-disabled-input-text-color: var(--c-black);
  --mat-select-disabled-trigger-text-color: var(--c-black);

  .mat-mdc-text-field-wrapper {
    background-color: var(--c-gray-5);
  }
}
