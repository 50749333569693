.v {
  &-page {
    @apply flex flex-col gap-6;

    &-header {
      @apply flex flex-col gap-2;
    }

    &-title {
      @apply text-xl font-medium;
      color: var(--c-neutral-variant-40);
    }

    &-breadcrumb {
      @apply text-sm font-normal;

      * {
        @apply text-gray-400 #{!important};
      }
    }
  }

  &-card {
    @apply p-6 flex flex-col gap-6 bg-white rounded-lg overflow-hidden;

    &.has-table {
      @apply block p-0 gap-0;

      .v-card-actions {
        @apply p-4 flex justify-end border-b border-gray-100;

        &.has-title {
          @apply justify-between items-center;
        }
      }
    }

    .v-card-actions {
      @apply flex justify-end gap-4;
    }

    .grid {
      @apply gap-x-6;
    }

    &.filters {
      @apply p-6 flex flex-col gap-6;

      .v-card-actions {
        @apply flex justify-end gap-4;
      }
    }

    &-title {
      @apply text-base font-semibold;
    }

    mat-paginator {
      @apply border-t border-gray-200;

      mat-form-field {
        @apply w-14 h-8;
      }

      .mat-mdc-select {
        @apply relative top-0;
      }

      .mdc-text-field--outlined {
        @apply px-2 py-1 #{!important};
      }

      .mat-mdc-form-field-infix {
        @apply p-0 min-h-0 h-4 #{!important};
      }
    }
  }

  &-table {
    @apply w-full;

    tr {
      @apply text-base font-normal h-16;
    }

    thead tr {
      @apply text-base font-semibold;
    }

    td,
    th {
      @apply border-b border-b-gray-100;

      button {
        @apply size-8 min-w-8 min-h-8 p-0 flex justify-center items-center #{!important};
      }

      .two-data {
        display: flex;
        flex-direction: column;
        align-items: start;
        gap: 4px;

        :nth-child(2) {
          color: gray;
        }
      }

      .action-wrapper {
        display: flex;
        align-items: center;
        padding: 10px;
      }

      .slide-toggle {
        display: flex;
        align-items: center;
        --mdc-switch-unselected-handle-color: rgba(255, 255, 255, 1);
      }

      .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled)
        .mdc-switch__shadow {
        background-color: rgba(119, 86, 255, 1);
      }

      .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled)
        .mdc-switch__track::after {
        background-color: rgba(203, 190, 255, 1) !important;
      }
    }
  }
}

mat-tab-group {
  --mdc-elevated-card-container-elevation: none;

  .tab-body {
    background-color: transparent !important;
    margin-top: 24px;
  }

  .tab-label {
    background-color: #ffffff !important;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none !important;
  margin: 0;
}

.validation {
  span {
    margin-left: var(--spacing-xs);
  }

  &.success {
    color: var(--c-text-success);
  }

  &.neutral {
    color: var(--c-gray-40);
  }

  &.error {
    color: var(--c-text-danger);
  }

  &:not(:first-child) {
    margin-top: var(--spacing-xxs);
  }
}

.checkbox-parent {
  display: flex;
  flex-direction: column;
  margin-top: 20px;

  .checkbox-container {
    display: flex;
    align-items: center;

    label {
      color: var(--c-gray-0);
      font-size: 16px;
      font-weight: 400;
      line-height: 18px;
      letter-spacing: 0.5px;
      @apply -ml-2;
    }

    i {
      @apply text-base mb-1;
    }

    .mat-mdc-checkbox.mat-mdc-checkbox-checked .mdc-checkbox__background,
    .mdc-checkbox__ripple {
      background-color: var(--c-primary-40);
      border-color: var(--c-primary-40);
      --mdc-checkbox-selected-checkmark-color: var(--c-white);
    }

    .material-symbols-outlined {
      color: #00000099 !important;
      margin-left: 6px !important;
      cursor: pointer !important;
      user-select: none;
    }
  }
}

.status-wrapper {
  padding: 8px;

  .status {
    display: block;
    min-width: 100px !important;
    color: var(--c-blue);
    border: 1px solid var(--c-blue);
    border-radius: 34px;
    padding: 2px 10px 2px 10px;
    text-align: center;
  }
}

.suffix-pad .mat-mdc-form-field-icon-suffix {
  @apply pe-4 text-gray-400 #{!important};
}

.upload-file-container {
  margin-top: 24px;

  .upload {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 12px 48px;
    margin-top: 12px;
    border: 1px dashed #00000021 !important;
    border-radius: 6px;

    span {
      margin-left: 10px;
    }

    cursor: pointer;
  }

  .error {
    border-color: #b00020 !important;
  }

  .uploaded {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-top: 12px;
    border-radius: 6px;
    border: 1px solid #d3d3d4;
    cursor: pointer;

    .start-items {
      display: flex;

      .image-placeholder {
        @apply flex items-center px-1;

        .material-symbols-outlined {
          display: flex;
        }
      }

      .file-info {
        display: flex;
        flex-direction: column;
        margin-left: 10px;

        .file-name {
          margin-top: 4px;
          font-size: 14px;
        }

        .file-size {
          margin-top: 2px;
          font-size: 12px;
          font-weight: 300;
          color: #00000099;
        }
      }
    }

    .end-items {
      display: flex;
      margin-right: 16px;
      align-items: center;

      .upload-failed {
        display: flex;
        margin-right: 16px;
        align-items: center;
        color: var(--c-error-50);

        .text {
          cursor: text;
          margin-right: 8px;
        }
      }
    }
  }

  .banner-uploaded {
    margin-top: 12px;
  }

  .upload-banner {
    margin-top: 12px;
  }
}

mat-chip {
  background-color: #ffffff !important;
  border: 1px solid rgba(162, 162, 164, 1);

  .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled)
    .mdc-switch__shadow {
    background-color: rgba(119, 86, 255, 1);
  }

  .mat-mdc-slide-toggle.mat-mdc-slide-toggle-checked:not(.mat-disabled)
    .mdc-switch__track::after {
    background-color: rgba(203, 190, 255, 1) !important;
  }
}

.mat-mdc-form-field-hint {
  @apply text-gray-400 text-sm;
}

.attached-fields {
  &.left {
    .mdc-notched-outline__trailing {
      border-top-right-radius: unset;
      border-bottom-right-radius: unset;
    }
  }

  &.right {
    .mdc-notched-outline__leading {
      border-top-left-radius: unset;
      border-bottom-left-radius: unset;
    }
  }
}
