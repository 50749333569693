// We want overlays to always appear over user content, so set a baseline
// very high z-index for the overlay container, which is where we create the new
// stacking context for all overlays.
$cdk-z-index-overlay-container: 1000;
$cdk-z-index-overlay: 1000;
$cdk-z-index-overlay-backdrop: 1000;

// Background color for all of the backdrops
$cdk-overlay-dark-backdrop-background: rgba(0, 0, 0, 0.288);

// Default backdrop animation is based on the Material Design swift-ease-out.
$backdrop-animation-duration: 400ms !default;
$backdrop-animation-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1) !default;

.cdk-overlay-container,
.cdk-global-overlay-wrapper {
  // Disable events from being captured on the overlay container.
  pointer-events: none;

  // The container should be the size of the viewport.
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  font-family: Roboto, serif;
}

// The overlay-container is an invisible element which contains all individual overlays.
.cdk-overlay-container {
  position: fixed;
  z-index: $cdk-z-index-overlay-container;

  &:empty {
    // Hide the element when it doesn't have any child nodes. This doesn't
    // include overlays that have been detached, rather than disposed.
    display: none;
  }
}

// We use an extra wrapper element in order to use make the overlay itself a flex item.
// This makes centering the overlay easy without running into the subpixel rendering
// problems tied to using `transform` and without interfering with the other position
// strategies.
.cdk-global-overlay-wrapper {
  display: -webkit-box;
  display: flex;
  position: absolute;
  z-index: $cdk-z-index-overlay;
}

// A single overlay pane.
.cdk-overlay-pane {
  // Note: it's important for this one to start off `absolute`,
  // in order for us to be able to measure it correctly.
  position: absolute;
  pointer-events: auto;
  box-sizing: border-box;
  z-index: $cdk-z-index-overlay;

  // For connected-position overlays, we set `display: flex` in
  // order to force `max-width` and `max-height` to take effect.
  display: -webkit-box;
  display: flex;
  max-width: 100%;
  max-height: 100%;
}

.cdk-overlay-backdrop {
  // TODO(jelbourn): reuse sidenav fullscreen mixin.
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: $cdk-z-index-overlay-backdrop;
  pointer-events: auto;
  -webkit-tap-highlight-color: transparent;
  -webkit-transition: opacity $backdrop-animation-duration $backdrop-animation-timing-function;
  transition: opacity $backdrop-animation-duration $backdrop-animation-timing-function;
  opacity: 0;

  &.cdk-overlay-backdrop-showing {
    opacity: 1;

    // In high contrast mode the rgba background will become solid so we need to fall back
    // to making it opaque using `opacity`. Note that we can't use the `cdk-high-contrast`
    // mixin, because we can't normalize the import path to the _a11y.scss both for the
    // source and when this file is distributed. See #10908.
    @media screen and (-ms-high-contrast: active) {
      opacity: 0.6;
    }
  }
}

.cdk-overlay-dark-backdrop {
  background: $cdk-overlay-dark-backdrop-background;
}

.cdk-overlay-transparent-backdrop {
  // Note: as of Firefox 57, having the backdrop be `background: none` will prevent it from
  // capturing the user's mouse scroll events. Since we also can't use something like
  // `rgba(0, 0, 0, 0)`, we work around the inconsistency by not setting the background at
  // all and using `opacity` to make the element transparent.
  &,
  &.cdk-overlay-backdrop-showing {
    opacity: 0;
  }
}

// Overlay parent element used with the connected position strategy. Used to constrain the
// overlay element's size to fit within the viewport.
.cdk-overlay-connected-position-bounding-box {
  position: absolute;
  z-index: $cdk-z-index-overlay;

  // We use `display: flex` on this element exclusively for centering connected overlays.
  // When *not* centering, a top/left/bottom/right will be set which overrides the normal
  // flex layout.
  display: -webkit-box;
  display: flex;

  // We use the `column` direction here to avoid some flexbox issues in Edge
  // when using the "grow after open" options.
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;

  // Add some dimensions so the element has an `innerText` which some people depend on in tests.
  min-width: 1px;
  min-height: 1px;
}

// Used when disabling global scrolling.
.cdk-global-scrollblock {
  position: fixed;

  // Necessary for the content not to lose its width. Note that we're using 100%, instead of
  // 100vw, because 100vw includes the width plus the scrollbar, whereas 100% is the width
  // that the element had before we made it `fixed`.
  width: 100%;

  // Note: this will always add a scrollbar to whatever element it is on, which can
  // potentially result in double scrollbars. It shouldn't be an issue, because we won't
  // block scrolling on a page that doesn't have a scrollbar in the first place.
  overflow-y: scroll;
}

.owl-dialog-container {
  position: relative;
  pointer-events: auto;
  box-sizing: border-box;
  display: block;
  padding: 1.5em;
  box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
  0px 24px 38px 3px rgba(0, 0, 0, 0.14),
  0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  border-radius: 2px;
  overflow: auto;
  background: #fff;
  color: rgba(0, 0, 0, 0.87);
  width: 100%;
  height: 100%;
  outline: none;
}

$theme-color: #6145d5;

.owl-dt-container,
.owl-dt-container * {
  box-sizing: border-box;
}

.owl-dt-container {
  display: block;
  font-size: 1rem;
  background: #ffffff;
  pointer-events: auto;
  z-index: 1000;
}

.owl-dt-container-row {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);

  &:last-child {
    border-bottom: none;
  }
}

.owl-dt-calendar {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  width: 100%;
}

.owl-dt-calendar-control {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  font-size: 1em;
  width: 100%;
  padding: 0.5em;
  color: #000000;

  .owl-dt-calendar-control-content {
    -webkit-box-flex: 1;
    flex: 1 1 auto;
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: start;
    -webkit-box-align: center;
    align-items: center;

    .owl-dt-calendar-control-button {
      padding: 0 0.8em;

      &:hover {
        background-color: rgba(0, 0, 0, 0.12);
      }
    }
  }
}

.owl-dt-calendar-main {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
  padding: 0 0.5em 0.5em;
  outline: 0;
}

.owl-dt-calendar-view {
  display: flex;
  -webkit-box-flex: 1;
  flex: 1 1 auto;
}

.owl-dt-calendar-multi-year-view {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;

  .owl-dt-calendar-table {
    width: calc(100% - 3em);

    .owl-dt-calendar-header th {
      padding-bottom: 0.25em;
    }
  }
}

.owl-dt-calendar-table {
  width: 100%;
  border-collapse: collapse;
  border-spacing: 0;

  .owl-dt-calendar-header {
    color: #c9c6c5;

    .owl-dt-weekdays {
      th {
        font-size: 0.7em;
        font-weight: 400;
        text-align: center;
        padding-bottom: 1em;
      }
    }

    .owl-dt-calendar-table-divider {

      &.owl-calendar-weeks {
        &:after {
          left: -1.8em;
        }
      }
    }
  }

  .owl-dt-calendar-cell {
    position: relative;
    height: 0;
    line-height: 0;
    text-align: center;
    outline: 0;
    color: rgba(0, 0, 0, 0.85);
    -webkit-appearance: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-tap-highlight-color: transparent;
  }

  .owl-dt-calendar-cell-content {
    position: absolute;
    top: 5%;
    left: 5%;
    display: -webkit-box;
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    box-sizing: border-box;
    width: 90%;
    height: 90%;
    font-size: 0.8em;
    line-height: 1;
    border: 1px solid transparent;
    border-radius: 999px;
    color: inherit;
    cursor: pointer;
  }

  .owl-dt-calendar-cell-out {
    opacity: 0.2;
  }

  .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
    background-color: rgba(201, 198, 197, 1);
  }

  .owl-dt-calendar-cell-selected {
    color: hsla(0, 0%, 100%, 0.85);
    background-color: $theme-color;

    &.owl-dt-calendar-cell-today {
      box-shadow: inset 0 0 0 1px hsla(0, 0%, 100%, 0.85);
    }
  }

  .owl-dt-calendar-cell-disabled {
    cursor: default;

    & > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
      color: rgba(0, 0, 0, 0.4);
    }

    & > .owl-dt-calendar-cell-content.owl-dt-calendar-cell-selected {
      opacity: 0.4;
    }

    & > .owl-dt-calendar-cell-today:not(.owl-dt-calendar-cell-selected) {
      border-color: rgba(0, 0, 0, 0.2);
    }
  }

  .owl-dt-calendar-cell-active:focus
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected),
  :not(.owl-dt-calendar-cell-disabled):hover
  > .owl-dt-calendar-cell-content:not(.owl-dt-calendar-cell-selected) {
    background-color: rgba(0, 0, 0, 0.04);
  }

  .owl-dt-calendar-cell-in-range {
    background: rgba($theme-color, 0.2);

    &.owl-dt-calendar-cell-range-from {
      border-top-left-radius: 999px;
      border-bottom-left-radius: 999px;
    }

    &.owl-dt-calendar-cell-range-to {
      border-top-right-radius: 999px;
      border-bottom-right-radius: 999px;
    }
  }

  &.owl-calendar-weeks {
    .owl-dt-calendar-cell-content {
      height: 86%; // adjust for the additional space taked for calendar weeks
    }
  }
}

.owl-dt-timer {
  //display: -webkit-box;
  //display: flex;
  //-webkit-box-pack: center;
  //justify-content: center;
  width: 100%;
  //height: 7em;
  //padding: .5em;
  //outline: none;
}

.owl-dt-timer-box {
  height: 100%;
}

.owl-dt-timer-content {
  //-webkit-box-flex: 1;
  //        flex: 1 1 auto;
  //display: -webkit-box;
  //display: flex;
  //-webkit-box-pack: center;
  //        justify-content: center;
  //-webkit-box-align: center;
  //        align-items: center;
  //width: 100%;
  //margin: .2em 0;

  .owl-dt-timer-input {
    //display: block;
    width: 20px;
    //text-align: center;
    border: none;
    //outline: medium none;
    //font-size: 1.2em;
    //padding: .2em;
  }
}

.owl-dt-timer-divider {
  display: inline-block;
  align-self: flex-end;
  position: absolute;
  width: 0.6em;
  height: 100%;
  left: -0.3em;

  &:before,
  &:after {
    content: "";
    display: inline-block;
    width: 0.35em;
    height: 0.35em;
    position: absolute;
    left: 50%;
    border-radius: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-color: currentColor;
  }

  &:before {
    top: 35%;
  }

  &:after {
    bottom: 35%;
  }
}

.owl-dt-control-button {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
  outline: none;
  border: none;
  -webkit-tap-highlight-color: transparent;
  display: inline-block;
  white-space: nowrap;
  text-decoration: none;
  vertical-align: baseline;
  margin: 3px;
  padding: 0;
  background-color: transparent;
  font-size: 1em;
  color: inherit;

  .owl-dt-control-button-content {
    position: relative;
    display: -webkit-inline-box;
    display: inline-flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    outline: none;
  }
}

.owl-dt-control-period-button {
  .owl-dt-control-button-content {
    height: 1.5em;
    padding: 0 0.5em;
    border-radius: 3px;
    -webkit-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
  }

  &:hover > .owl-dt-control-button-content {
    background-color: rgba(0, 0, 0, 0.12);
  }

  .owl-dt-control-button-arrow {
    display: -webkit-box;
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    align-items: center;
    width: 1.5em;
    height: 1.5em;
    margin: 0.1em;
    -webkit-transition: -webkit-transform 200ms ease;
    transition: -webkit-transform 200ms ease;
    transition: transform 200ms ease;

    svg {
      width: 50%;
      height: 50%;
      fill: currentColor;
    }
  }
}

.owl-dt-control-arrow-button {
  .owl-dt-control-button-content {
    padding: 0;
    border-radius: 50%;
    width: 1.5em;
    height: 1.5em;
  }

  &[disabled] {
    color: rgba(0, 0, 0, 0.4);
    cursor: default;
  }

  svg {
    width: 50%;
    height: 50%;
    fill: currentColor;
  }
}

.owl-dt-inline-container,
.owl-dt-popup-container {
  position: relative;
  width: 18.5em;
  box-shadow: 0 12px 16px -4px rgba(16, 24, 40, 0.08);
  border-radius: 4px;

  .owl-dt-calendar,
  .owl-dt-timer {
    width: 100%;
  }

  .owl-dt-calendar {
    height: 20.25em;
  }
}

.owl-dt-dialog-container {
  max-height: 95vh;
  margin: -1.5em;

  .owl-dt-calendar {
    min-width: 250px;
    min-height: 330px;
    max-width: 750px;
    max-height: 750px;
  }

  .owl-dt-timer {
    min-width: 250px;
    max-width: 750px;
  }
}

@media all and (orientation: landscape) {
  .owl-dt-dialog-container {
    .owl-dt-calendar {
      width: 58vh;
      height: 62vh;
    }

    .owl-dt-timer {
      width: 58vh;
    }
  }
}

@media all and (orientation: portrait) {
  .owl-dt-dialog-container {
    .owl-dt-calendar {
      width: 80vw;
      height: 80vw;
    }

    .owl-dt-timer {
      width: 80vw;
    }
  }
}

.owl-dt-container-buttons {
  display: -webkit-box;
  display: flex;
  width: 100%;
  height: 2em;
  color: $theme-color;
}

.owl-dt-container-control-button {
  font-size: 1em;
  width: 50%;
  height: 100%;
  border-radius: 0;

  .owl-dt-control-button-content {
    height: 100%;
    width: 100%;
    -webkit-transition: background-color 100ms linear;
    transition: background-color 100ms linear;
  }

  &:hover {
    .owl-dt-control-button-content {
      background-color: rgba(0, 0, 0, 0.1);
    }
  }
}

.owl-dt-container-info {
  padding: 0 0.5em;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  .owl-dt-container-range {
    outline: none;

    .owl-dt-container-range-content {
      display: -webkit-box;
      display: flex;
      -webkit-box-pack: justify;
      justify-content: space-between;
      padding: 0.5em 0;
      font-size: 0.8em;
    }

    &:last-child {
      border-top: 1px solid rgba(0, 0, 0, 0.12);
    }
  }

  .owl-dt-container-info-active {
    color: $theme-color;
  }
}

.owl-dt-container-disabled,
.owl-dt-trigger-disabled {
  opacity: 0.35;
  filter: Alpha(Opacity=35);
  background-image: none;
  cursor: default !important;
}

// todo: temp
//.owl-dt-timer-hour12 {
//  display: -webkit-box;
//  display: flex;
//  -webkit-box-pack: center;
//          justify-content: center;
//  -webkit-box-align: center;
//          align-items: center;
//  color: $theme-color;
//
//  .owl-dt-timer-hour12-box {
//    border: 1px solid currentColor;
//    border-radius: 2px;
//    -webkit-transition: background 200ms ease;
//    transition: background 200ms ease;
//
//    .owl-dt-control-button-content {
//      width: 100%;
//      height: 100%;
//      padding: .5em;
//    }
//
//    &:focus, &:hover {
//      .owl-dt-control-button-content {
//        background: $theme-color;
//        color: #ffffff;
//      }
//    }
//  }
//}

.owl-dt-calendar-only-current-month {
  .owl-dt-calendar-cell-out {
    visibility: hidden;
    cursor: default;
  }
}

.owl-dt-inline {
  display: inline-block;
}

.owl-dt-control {
  outline: none;
  cursor: pointer;

  .owl-dt-control-content {
    outline: none;
  }

  &:focus > .owl-dt-control-content {
    background-color: rgba(0, 0, 0, 0.12);
  }

  &:not(:-moz-focusring):focus > .owl-dt-control-content {
    box-shadow: none;
  }
}

.owl-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}
